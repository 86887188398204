import jwt from 'jsonwebtoken';
import { userRoles, intergationTypes } from '~/enums/integrations';
import { accountTypes } from '~/enums/account';
import { clientTypes } from '~/enums/iframe';

export default {
  integratorAccount(state) {
    const currentUserId = state?.user?.guid;
    const currentUserFromUsers = state.users?.find(({ userId }) => userId === currentUserId);
    return ['integrator', 'admin'].includes(currentUserFromUsers?.role);
  },

  onlyIntegratorAccount(state) {
    const currentUserId = state?.user?.guid;
    const currentUserFromUsers = state.users?.find(({ userId }) => userId === currentUserId);
    return currentUserFromUsers?.role === 'integrator';
  },

  partnerTechPartnerAccount(state) {
    return [accountTypes.PARTNER, accountTypes.TECH_PARTNER].includes(state.account.type);
  },

  langCode(state) {
    const langCodes = {
      ru: 'ru-RU',
      en: 'en-US',
      es: 'es',
      pt: 'pt-BR'
    };
    return langCodes[state.account?.lang || 'ru'];
  },

  isIntegrationToken(state) {
    return !!state.integrationInfo;
  },

  chatsForTechPartner(state) {
    return [accountTypes.TECH_PARTNER_POSTPAY, accountTypes.CHILD_POSTPAY].includes(state?.account?.type);
  },

  childPostpay(state) {
    return state?.account?.type === accountTypes.CHILD_POSTPAY;
  },

  frontendData(state) {
    return state.account?.frontendData ? JSON.parse(state.account.frontendData) : {};
  },

  decodedIFrameToken(state) {
    return state.iFrameToken ? jwt.decode(state.iFrameToken) : {};
  },

  hasManagerRole(state) {
    if (!state.integrationInfo?.userRoles || !!state.chatErrorState) return false;

    const result = Object.keys(state.integrationInfo.userRoles).find((guid) => {
      const channel = state.integrationInfo.userRoles[guid];
      return channel.role === userRoles.manager;
    });

    return !!result;
  },

  clientTypeIs1C(_, getters) {
    return getters.decodedIFrameToken?.integration?.options?.clientType === clientTypes['1c'];
  },

  integrationPipedrive(state) {
    return state.integrationInfo?.type === intergationTypes.pipedrive;
  },

  isUserRegisteredAfterCabinetInIframeReleased(state) {
    return state.account?.registerAt > 1693898340000;
  },

  yclientsIntegration(state) {
    return state.account.crmType === intergationTypes.yclients;
  }
};
