import { render, staticRenderFns } from "./AppDrawer.vue?vue&type=template&id=fcbff26e&scoped=true&"
import script from "./AppDrawer.vue?vue&type=script&lang=js&"
export * from "./AppDrawer.vue?vue&type=script&lang=js&"
import style0 from "./AppDrawer.vue?vue&type=style&index=0&id=fcbff26e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fcbff26e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseInfoLink: require('/builds/wazzup/front/components/base/BaseInfoLink.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
installComponents(component, {VBtn,VIcon,VNavigationDrawer})
