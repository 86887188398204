import sha256 from 'js-sha256';
import BaseService from '~/services/BaseService';
export default class AuthService extends BaseService {
  async login(data) {
    const result = await this.$apiAuth.$post('/login', data, { ignoreNotify: true });
    if (data?.target === 'bitrix') {
      this.$cookies.set('accessTokenIframe', result.accessToken, {
        path: '/',
        domain: `.${this.$config.appDomain}`,
        maxAge: 2592000,
        sameSite: 'none',
        secure: true
      });
      localStorage.setItem('accessTokenIframe', result.accessToken);
      this.$cookies.set('refreshTokenIframe', result.refreshToken, {
        path: '/',
        domain: `.${this.$config.appDomain}`,
        maxAge: 2592000,
        sameSite: 'none',
        secure: true
      });
      localStorage.setItem('refreshTokenIframe', result.refreshToken);
      this.$cookies.set('accountIdIframe', result.accountId, {
        path: '/',
        domain: `.${this.$config.appDomain}`,
        maxAge: 2592000,
        sameSite: 'none',
        secure: true
      });
      localStorage.setItem('accountIdIframe', result.accountId);
    } else {
      this.$cookies.set('accessToken', result.accessToken, {
        path: '/',
        domain: `.${this.$config.appDomain}`,
        maxAge: 2592000,
        sameSite: 'none',
        secure: true
      });
      this.$cookies.set('refreshToken', result.refreshToken, {
        path: '/',
        domain: `.${this.$config.appDomain}`,
        maxAge: 2592000,
        sameSite: 'none',
        secure: true
      });
      this.$cookies.set('accountId', result.accountId, {
        path: '/',
        domain: `.${this.$config.appDomain}`,
        maxAge: 2592000,
        sameSite: 'none',
        secure: true
      });
    }
    return result;
  }

  async register({ email, lang, timeZone, abTests, target }) {
    const cookieFields = {
      __ref: 'ref',
      __referer: 'referrer',
      _ga: 'gaId',
      _ym_uid: 'ymId'
    };

    const cookieData = Object.entries(cookieFields).reduce((acc, [cookieKey, fieldKey]) => {
      const cookieValue = this.$cookies.get(cookieKey, { parseJSON: false });
      if (cookieValue) {
        acc[fieldKey] = String(cookieValue);
      }
      return acc;
    }, {});

    const result = await this.$api.$post(
      '/users/signup',
      {
        email,
        lang,
        timeZone,
        target,
        info: {
          abTests,
          url: cookieData.ref || localStorage.getItem('__ref'),
          referer: cookieData.referrer || localStorage.getItem('__referer'),
          gaId: cookieData.gaId,
          ymId: cookieData.ymId,
          userAgent: navigator.userAgent
        }
      },
      { ignoreNotify: true }
    );

    const { accessToken, refreshToken, accountId } = result.data;
    if (target === 'bitrix') {
      this.$cookies.set('accessTokenIframe', accessToken, {
        path: '/',
        domain: `.${this.$config.appDomain}`,
        maxAge: 2592000,
        sameSite: 'none',
        secure: true
      });
      localStorage.setItem('accessTokenIframe', accessToken);
      this.$cookies.set('refreshTokenIframe', refreshToken, {
        path: '/',
        domain: `.${this.$config.appDomain}`,
        maxAge: 2592000,
        sameSite: 'none',
        secure: true
      });
      localStorage.setItem('refreshTokenIframe', refreshToken);
      this.$cookies.set('accountIdIframe', accountId, {
        path: '/',
        domain: `.${this.$config.appDomain}`,
        maxAge: 2592000,
        sameSite: 'none',
        secure: true
      });
      localStorage.setItem('accountIdIframe', accountId);
    } else {
      this.$cookies.set('accessToken', accessToken, {
        path: '/',
        domain: `.${this.$config.appDomain}`,
        maxAge: 2592000,
        sameSite: 'none',
        secure: true
      });
      this.$cookies.set('refreshToken', refreshToken, {
        path: '/',
        domain: `.${this.$config.appDomain}`,
        maxAge: 2592000,
        sameSite: 'none',
        secure: true
      });
      this.$cookies.set('accountId', accountId, {
        path: '/',
        domain: `.${this.$config.appDomain}`,
        maxAge: 2592000,
        sameSite: 'none',
        secure: true
      });
    }
    return result;
  }

  async restore(data) {
    const result = await this.$api.$post('/users/password/recovery', data, { ignoreNotify: true });
    return result;
  }

  async refresh() {
    const { accessToken, refreshToken } = await this.$apiAuth.$post(
      '/refresh-tokens',
      {
        refreshToken:
          this.$config.cabinetInIframe?.bitrix &&
          (this.$cookies.get('refreshTokenIframe') || localStorage.getItem('refreshTokenIframe'))
            ? this.$cookies.get('refreshTokenIframe')
            : this.$cookies.get('refreshToken')
      },
      { ignoreNotify: true }
    );
    if (this.$config.cabinetInIframe?.bitrix) {
      this.$cookies.set('accessTokenIframe', accessToken, {
        path: '/',
        domain: `.${this.$config.appDomain}`,
        maxAge: 2592000,
        sameSite: 'none',
        secure: true
      });
      localStorage.setItem('accessTokenIframe', accessToken);
      this.$cookies.set('refreshTokenIframe', refreshToken, {
        path: '/',
        domain: `.${this.$config.appDomain}`,
        maxAge: 2592000,
        sameSite: 'none',
        secure: true
      });
      localStorage.setItem('refreshTokenIframe', refreshToken);
    } else {
      this.$cookies.set('accessToken', accessToken, {
        path: '/',
        domain: `.${this.$config.appDomain}`,
        maxAge: 2592000,
        sameSite: 'none',
        secure: true
      });
      this.$cookies.set('refreshToken', refreshToken, {
        path: '/',
        domain: `.${this.$config.appDomain}`,
        maxAge: 2592000,
        sameSite: 'none',
        secure: true
      });
    }

    return accessToken;
  }

  async logout(onlyRemoveCookies = false) {
    if (
      this.$config.cabinetInIframe?.bitrix &&
      (this.$cookies.get('refreshTokenIframe') || localStorage.getItem('refreshTokenIframe'))
    ) {
      const refreshToken = this.$cookies.get('refreshTokenIframe') || localStorage.getItem('refreshTokenIframe') || '';
      if (!onlyRemoveCookies) {
        try {
          await this.$apiAuth.$post('/logout/', { refreshToken }, { ignoreNotify: true });
        } catch (err) {
          console.log(err);
        }
      }
      this.$cookies.set('accessTokenIframe', '', {
        path: '/',
        domain: `.${this.$config.appDomain}`,
        maxAge: 2592000,
        sameSite: 'none',
        secure: true
      });
      localStorage.removeItem('accessTokenIframe');
      this.$cookies.set('refreshTokenIframe', '', {
        path: '/',
        domain: `.${this.$config.appDomain}`,
        maxAge: 2592000,
        sameSite: 'none',
        secure: true
      });
      localStorage.removeItem('refreshTokenIframe');
      this.$cookies.set('accountIdIframe', '', {
        path: '/',
        domain: `.${this.$config.appDomain}`,
        maxAge: 2592000,
        sameSite: 'none',
        secure: true
      });
      localStorage.removeItem('accountIdIframe');
      this.$cookies.remove('accessTokenIframe', { path: '/', domain: `.${this.$config.appDomain}` });
      this.$cookies.remove('refreshTokenIframe', { path: '/', domain: `.${this.$config.appDomain}` });
      this.$cookies.remove('accountIdIframe', { path: '/', domain: `.${this.$config.appDomain}` });
    } else {
      const refreshToken = this.$cookies.get('refreshToken') || '';
      if (!onlyRemoveCookies) {
        try {
          await this.$apiAuth.$post('/logout/', { refreshToken }, { ignoreNotify: true });
        } catch (err) {
          console.log(err);
        }
      }
      this.$cookies.remove('accessToken', { path: '/', domain: `.${this.$config.appDomain}` });
      this.$cookies.remove('refreshToken', { path: '/', domain: `.${this.$config.appDomain}` });
      this.$cookies.remove('accountId', { path: '/', domain: `.${this.$config.appDomain}` });
    }
  }

  logoutWeb() {
    localStorage.removeItem('managerToken');
    localStorage.removeItem('managerAccountId');
    location.href = '/login';
  }

  async resetPassword(email, password) {
    await this.$api.$post('/users/password/change', {
      email: email.toLowerCase(),
      hashPassword: sha256(`${email.toLowerCase()}!${password}`)
    });
  }

  async sendPhoneCode(data) {
    const result = await this.$apiV2.$post('/mobile/sendPhoneCode', data, { ignoreNotify: true });
    localStorage.countdownTimer = new Date().getTime();
    return result;
  }

  async managerLogin(data) {
    const result = await this.$apiV2.$post('mobile/auth', data, { ignoreNotify: true });
    localStorage.setItem('managerToken', result.token);
    localStorage.setItem('managerAccountId', result.accountId);
    return result;
  }

  async getIntegrationInfo() {
    const result = await this.$apiV2.$get('/integrations/get-integration-data');
    return result;
  }

  async sendRating(data) {
    const result = await this.$apiV2.$post('/rating', data);
    return result;
  }
}
